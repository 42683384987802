import { Injectable } from '@angular/core';
import { NavigationCancel, Router } from '@angular/router';
import { sanitizeUrl } from '@helpers';
import { IUserTokenPayload } from '@interfaces';
import { AuthService } from '@services';
import { filter } from 'rxjs/operators';
import { EventQueueService } from '../services/event-queue.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  guardedURL: string = undefined;

  constructor(
    private authService: AuthService,
    private router: Router,
    private eventQueue: EventQueueService,
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationCancel))
      .subscribe((event: NavigationCancel) => {
        this.guardedURL = event.url;
      });
  }

  canActivate() {
    const isAuthenticated = this.authService.isAuthenticated;

    if (!isAuthenticated) {
      setTimeout(() => {
        this.router.navigate(['/auth/sign-in'], {
          queryParams: { comingFrom: sanitizeUrl(this.guardedURL) },
        });
      }, 50);
      return;
    }

    // Kick the user out if their token is not valid
    const isValid = this.authService.isValidToken;
    if (!isValid) {
      this.eventQueue.dispatch('FORCE_SIGN_OUT', 'Your token is not valid');
      return false;
    }

    const tokenObject: IUserTokenPayload = this.authService.tokenObject;
    // Check that the user has verified their email
    if (tokenObject.email_verified === false) {
      // If they haven't, redirect them to the email verification page
      this.router.navigate(['/auth/verify-email'], {
        queryParams: { comingFrom: sanitizeUrl(this.guardedURL) },
      });
      return false;
    }
    return true;
  }
}
