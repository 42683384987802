import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services';

/**
 * This guard prevents logged in users from accessing a page
 */
@Injectable({
  providedIn: 'root',
})
export class NonAuthGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    const isAuthenticated = this.authService.isAuthenticated;

    if (isAuthenticated) {
      // If user is authenticated, redirect them to the main application page
      // You can modify this to redirect to any appropriate page for authenticated users
      this.router.navigate(['/']);
      return false;
    }

    // If user is not authenticated, allow access to auth pages
    return true;
  }
}
