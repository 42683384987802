import { Injectable } from '@angular/core';
import { AuthService } from '@services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(private authService: AuthService) {}

  canActivate() {
    return this.authService.tokenObject$.pipe(
      map((tokenObj) => {
        return tokenObj.role === 'admin';
      }),
    );
    return true;
  }
}
